/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { Fragment } from "react"
import styled from "styled-components"
import { RenderNode } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, Block, Inline, Text } from "@contentful/rich-text-types"
import {
  Variables,
  media,
  Interfaces,
  EventCard,
  ContentfulRichTextImage,
  ArticleCTATextLink
} from "@life-without-barriers/react-components"

import {
  RemoteAssets,
  MediaEvent,
  MediaArticleBody,
  MediaCTATextLink,
  MediaCTAAssetDownload
} from "../../contentTypes"

const { greyLight, black } = Variables

const findQuoteAttribution = (
  node: Block | Inline | Text
): string | undefined => {
  if (node.nodeType === BLOCKS.PARAGRAPH) {
    const [nodeContent] = node.content
    return nodeContent.nodeType === "text" && nodeContent.value.startsWith("--")
      ? nodeContent.value.replace("--", "")
      : undefined
  }
  return undefined
}

const StyledH2 = styled.h2`
  margin-top: 3rem;
`

const StyledH3 = styled.h3`
  h3& {
    font-size: 18px;
    margin-top: 3rem;
  }
`

const nodeRenderers = (assets: RemoteAssets[]): RenderNode => ({
  [BLOCKS.HEADING_2]: (_, children) => (
    <StyledH2 className="ts-display-4 fw8">{children}</StyledH2>
  ),
  [BLOCKS.HEADING_3]: (_, children) => (
    <StyledH3 className="ts-display-5 fw8">{children}</StyledH3>
  ),
  [BLOCKS.PARAGRAPH]: (_, children) => {
    return children && <p className="copy">{children}</p>
  },
  [BLOCKS.UL_LIST]: (_, children) => <ul className="pl4">{children}</ul>,
  [BLOCKS.OL_LIST]: (_, children) => <ol className="pl4">{children}</ol>,
  [BLOCKS.LIST_ITEM]: (_, children) => <li className="pv1">{children}</li>,
  [BLOCKS.HR]: () => <HorizontalRule className="mv5 bw0 bg-lwb-light-dark" />,
  [BLOCKS.QUOTE]: (node, children) => {
    const blockNode = node as Block
    const lastChild = blockNode.content[blockNode.content.length - 1]
    const attribution = findQuoteAttribution(lastChild)
    const blockquoteClassNames = "mh0 ph0 mv4"
    return attribution ? (
      <blockquote className={blockquoteClassNames}>
        {React.Children.toArray(children).slice(0, -1)}
        <p className="attribution ttu tracked">
          <span aria-hidden="true">&mdash; </span>
          {attribution}
        </p>
      </blockquote>
    ) : (
      <blockquote className={blockquoteClassNames}>{children}</blockquote>
    )
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node) => {
    const fields = node.data.target
    if (!fields) {
      return
    }

    const { __typename } = fields
    if (!__typename) {
      return
    }

    if (__typename === "ContentfulMediaEvent") {
      const eventFields = {
        ...fields,
        location: fields.location.location as string,
        description: fields.markdown.description as string
      }
      return <EventCard {...(eventFields as MediaEvent)} />
    }

    if (__typename === "ContentfulMediaCtaTextLink") {
      return (
        <div className="mv3">
          <ArticleCTATextLink {...(fields as MediaCTATextLink)} />
        </div>
      )
    }

    if (__typename === "ContentfulMediaCtaAssetDownload") {
      const { label, asset } = fields as MediaCTAAssetDownload
      if (!asset) {
        return
      }
      return (
        <div className="mv3">
          <ArticleCTATextLink label={label} url={asset.file.url} download />
        </div>
      )
    }
  },
  [BLOCKS.EMBEDDED_ASSET]: (node) => {
    if (!node.data.target) {
      return
    }
    const fields = node.data.target
    if (!fields || !fields.file) {
      return
    }
    if (fields.file.contentType.startsWith("image")) {
      return <ContentfulRichTextImage fields={fields} assets={assets} />
    }
  }
})

const textRenderer = (text: string) => (
  <>
    {text.split("\n").map((line, i) => (
      <Fragment key={i}>
        {i !== 0 && <br />}
        {line}
      </Fragment>
    ))}
  </>
)

interface Props {
  richTextBody: MediaArticleBody
  className?: string
}

const RichText = ({ richTextBody, className }: Props) => (
  <ContentfulRichText className={className}>
    {renderRichText(richTextBody, {
      renderNode: nodeRenderers(richTextBody.references),
      renderText: textRenderer
    })}
  </ContentfulRichText>
)

const ContentfulRichText = styled.div`
  blockquote {
    color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};

    p {
      font-size: 1.5rem;
      line-height: 1.4em;
      font-weight: 300;
      text-align: center;
      padding: 0;
    }

    p.attribution {
      font-size: 12px;
    }
  }

  ${media.notSmall`
    blockquote {
      p {
        font-size: 2.25rem;
        line-height: 1.4em;
        font-weight: 300;
      }
    }
  `}

  li {
    p {
      padding: 0;
      margin: 0;
    }
  }

  table {
    border-collapse: collapse;
    margin: 2rem 0;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.light};
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem 0.75rem;
    margin: 0;
    vertical-align: top;
  }

  tr:nth-child(even) {
    background-color: ${greyLight};
  }

  a {
    :not(.cta-link) {
      color: ${black};
      &:hover {
        color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
      }
    }
  }
`

const HorizontalRule = styled.hr.attrs({ "aria-hidden": "true" })`
  height: 1px;
`

export default RichText

import React from "react"
import {
  Helmet,
  Layout,
  Theme,
  Link,
  IconArrowRight,
  Box,
  Container,
  Row,
  Banner,
  routesObject
} from "@life-without-barriers/react-components"
import { graphql } from "gatsby"
import { Site } from "@life-without-barriers/gatsby-common"
import { helpers } from "@life-without-barriers/utilities"
import { IGatsbyImageData } from "gatsby-plugin-image"

import { MediaArticle } from "../../contentTypes"
import ContentfulRichText from "../../components/media/ContentfulRichText"

const { dasherize } = helpers

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    currentPage: MediaArticle
    ogImage: IGatsbyImageData
  }
}

const ArticleTemplate = ({
  location,
  data: {
    site: { siteMetadata },
    currentPage,
    ogImage
  }
}: Props) => {
  const { title, introductionText, category, date, body } = currentPage

  const articleCategory = dasherize(category).toLowerCase()
  const categoryTheme = Theme.getThemeFromCategory(articleCategory)

  return (
    <div>
      <Helmet
        title={`${title} | ${siteMetadata.title}`}
        description={introductionText.text}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={categoryTheme.medium}
        image={ogImage}
      />
      <Layout theme={categoryTheme}>
        <Banner
          title={title}
          breadcrumbs={[
            routesObject.home,
            {
              to: "/media",
              text: "Media releases",
              title: "Return to main media releases"
            }
          ]}
          share={true}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <p className="pb3 x-small ttu f7 f6-ns mt0 color-lwb-grey-darker tracked">
                {date}
              </p>
              <ContentfulRichText richTextBody={body} />
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l">
              <div className="lwb-shadow ml5-l pa4-ns pa3 br2">
                <div>
                  <p className="pb3 x-small ttu f7 f6-ns mt0 color-lwb-grey-darker tracked">
                    Related links
                  </p>
                  <div className="mt2 mb4">
                    <h2 className="color-lwb-theme-medium ts-display-4 mb3">
                      News and events
                    </h2>
                    <Link className="color-lwb-black fw4 db mv2" to="/media/">
                      Latest media releases
                      <IconArrowRight
                        height="8"
                        width="5"
                        color={categoryTheme.dark}
                        className="ml2"
                      />
                    </Link>
                    <Link
                      className="color-lwb-black fw4 db mv2"
                      to="/news/"
                      rooted
                    >
                      Latest news stories
                      <IconArrowRight
                        height="8"
                        width="5"
                        color={categoryTheme.dark}
                        className="ml2"
                      />
                    </Link>
                    <Link
                      className="color-lwb-black fw4 db mv2"
                      to="https://www.eventbrite.com.au/o/life-without-barriers-18159721409"
                      rooted
                    >
                      Upcoming events
                      <IconArrowRight
                        height="8"
                        width="5"
                        color={categoryTheme.dark}
                        className="ml2"
                      />
                    </Link>
                  </div>
                  <div className="mt2 mb4">
                    <h2 className="color-lwb-theme-medium ts-display-4 mb3">
                      About Life Without Barriers
                    </h2>
                    <Link
                      className="color-lwb-black fw4 db mv2"
                      to="/about-us/"
                      rooted
                    >
                      About us
                      <IconArrowRight
                        height="8"
                        width="5"
                        color={categoryTheme.dark}
                        className="ml2"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    currentPage: contentfulMediaArticle(id: { eq: $id }) {
      id
      slug
      title
      category
      introductionText {
        text: introductionText
      }
      date: date(formatString: "D MMMM YYYY")
      body {
        raw
        references {
          ...RemoteContentfulMediaAsset
          ...RemoteContentfulMediaCtaAssetDownload
          ...RemoteContentfulMediaCtaTextLink
          ...RemoteContentfulMediaEvent
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { regex: "/media/banner.webp/" }) {
      childImageSharp {
        gatsbyImageData(formats: JPG, width: 1200)
      }
    }
  }
`

export default ArticleTemplate
